import { toast } from 'react-toastify';

import { createContext } from 'react';
//enums
import { ThemeEnum } from '../enums';

type IThemeContext = {
    theme: ThemeEnum;
    setTheme: (theme: ThemeEnum) => void;
};

const ThemeContext = createContext<IThemeContext>({
    theme: ThemeEnum.Standard,
    setTheme: () => {
        toast("SetTheme function is not yet defined. Wrap your component with ThemeProvider.");
    },
});

export default ThemeContext;
