import { BrowserRouter as Router } from 'react-router-dom';
import combineContextProviders from './combineContextProviders';
import ThemeProvider from 'core/theme/ThemeProvider';
import SettingsProvider from './settings/SettingsProvider';


const providers = [
    Router,
    ThemeProvider,
    SettingsProvider
];

const CoreProvider = combineContextProviders(...providers);

export default CoreProvider;