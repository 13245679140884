/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementType, ReactNode } from "react";

const combineContextProviders = (...components: ElementType[]) => {
    return components.reduce(
        (AccumulatedComponents: ElementType, CurrentComponent: ElementType) => {
            const TempComponent = ({ children }: { children: ReactNode }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
            TempComponent.displayName = "CombinedContextProviders";

            return TempComponent;
        },
        ({ children }: { children: ReactNode }) => <>{children}</>
    );
};


export default combineContextProviders;
