import { toast } from 'react-toastify';
import { createContext } from 'react';

type SubscribeContext = {
    subscription: boolean;
    handleSubscriber: (value: boolean) => void;
    handleSort: (value: boolean) => void; // true for newest, false for oldest
    lastSeenDateActive: boolean;
    sort: boolean;
    popupBoxActive: boolean;
    handleCookieLastDate: (value: boolean) => void;
    handlePopupBox: (value: boolean) => void;
};

const SettingsContext = createContext<SubscribeContext>({
    subscription: false,
    lastSeenDateActive: false,
    popupBoxActive: false,
    sort: true,
    handleSort: () => {
        toast("Sort function is not yet defined.");
    },
    handleSubscriber: () => {
        toast("Subscribed function is not yet defined.");
    },
    handleCookieLastDate: () => {
        toast("Cookie function is not yet defined.");
    },
    handlePopupBox: () => {
        toast("Popup function is not yet defined.");
    }
});

export default SettingsContext;

