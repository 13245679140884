import { FC, useState, ReactNode } from 'react';
import  secureLocalStorage  from  'react-secure-storage';
import { useNavigate } from "react-router-dom";

//contexts
import { SettingsContext } from './context';

type SettingsProviderProps = {
    children?: ReactNode;
};
const storage = secureLocalStorage.getItem('isSubscribed');
const subscribed: boolean = storage === "true";

const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
    const [subscription, setSubscription] = useState<boolean>(subscribed);
    const [lastSeenDateActive, setLastSeenDateActive] = useState<boolean>(false);
    const [popupBoxActive, setPopupBoxActive] = useState<boolean>(false);
    const [sort, setSort] = useState<boolean>(true);

    const navigate = useNavigate();

    const refreshPage = () => {
      navigate(0);
    };



    const toggleState = (activeState: boolean, callback: (value: boolean) => void, name?: string) => () => {
        const newValue = !activeState;
        callback(newValue);
        if(name === 'subscription') {
            secureLocalStorage.removeItem('isSubscribed')
            secureLocalStorage.setItem('isSubscribed', JSON.stringify(newValue));
            refreshPage();
        }
    };

    const handleSubscriber = toggleState(subscription, setSubscription, 'subscription');
    const handleCookieLastDate = toggleState(lastSeenDateActive, setLastSeenDateActive);
    const handlePopupBox = toggleState(popupBoxActive, setPopupBoxActive);
    const handleSort = toggleState(sort, setSort);

    return (
        <SettingsContext.Provider value={{
            subscription,
            lastSeenDateActive,
            popupBoxActive,
            handleSubscriber,
            handleCookieLastDate,
            handlePopupBox,
            handleSort,
            sort
        }}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
