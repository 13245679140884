import { FC, useEffect, useState, ReactNode } from 'react';
//enums
import { ThemeEnum } from './enums';
//contexts
import { ThemeContext } from './context';

type ThemeProviderProps = {
    children?: ReactNode;
};

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const [theme, setTheme] = useState<ThemeEnum>(ThemeEnum.Standard);

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
